// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import styles from '../styles/CookieConsent.module.css';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the user has already accepted the cookie policy
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        // Set a cookie to remember the user's consent
        Cookies.set('cookieConsent', 'true', { expires: 365, path: '/' });
        setIsVisible(false);
    };

    if (!isVisible) {
        return null; // Don't render the pop-up if consent has been given
    }

    return (
        <div className={styles["cookie-consent-popup"]}>
            <div className={styles["cookie-consent-content"]}>
                <div className={styles['cookie-consent-text']}>
                    <h2>We value your privacy</h2>
                    <p>
                        We use cookies to manage your session and may store your test answers
                        in localStorage. By continuing to use this site, you consent to the
                        use of these technologies.
                    </p>
                    <p>
                        No personal information is stored in the cookies, and we do not
                        share any data with third parties.
                    </p>
                </div>
                <button onClick={handleAccept} className={styles["cookie-consent-button"]}>
                    Accept
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;
