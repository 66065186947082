import React from 'react';
import styles from '../styles/PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <div className={styles["privacy-policy-container"]}>
            <h1>Privacy Policy</h1>
            <p>Last updated: 22/08/2042</p>

            <h2>Introduction</h2>
            <p>
                Welcome to Wanteed! We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
            </p>

            <h2>Information We Collect</h2>
            <p>
                We may collect information about you in a variety of ways. The information we may collect on the Site includes:
            </p>
            <ul>
                <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and telephone number, and demographic information.</li>
                <li><strong>Test Data:</strong> Information about your test answers and results.</li>
                <li><strong>Usage Data:</strong> Information about how you use our website and services.</li>
                <li><strong>Tracking Technologies:</strong> Cookies and web beacons.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>
                We use the information we collect in the following ways:
            </p>
            <ul>
                <li>To provide, operate, and maintain our website and services.</li>
                <li>To improve, personalize, and expand our website and services.</li>
                <li>To understand and analyze how you use our website and services.</li>
                <li>To develop new products, services, features, and functionality.</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>
                We may share the information we have collected about you in certain situations. Your information may be disclosed as follows:
            </p>
            <ul>
                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others.</li>
                <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
            </ul>

            <h2>Security of Your Information</h2>
            <p>
                We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>

            <h2>Your Rights</h2>
            <p>
                Depending on your location, you may have the following rights regarding your personal data:
            </p>
            <ul>
                <li><strong>Access:</strong> You may request access to the personal data we hold about you.</li>
                <li><strong>Rectification:</strong> You have the right to request that we correct any inaccuracies in your personal data.</li>
                <li><strong>Deletion:</strong> You may request that we delete your personal data.</li>
                <li><strong>Data Portability:</strong> You may request a copy of the personal data we have about you in a structured, commonly used, and machine-readable format.</li>
                <li><strong>Object to Processing:</strong> You have the right to object to the processing of your personal data in certain circumstances.</li>
            </ul>

            <h2>Contact Us</h2>
            <p>
                If you have questions or comments about this Privacy Policy, please contact us at:
            </p>
            <p><strong>Email:</strong> TODO</p>
            <p><strong>Address:</strong> TODO</p>
        </div>
    );
};

export default PrivacyPolicy;
