// src/pages/Intro.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Intro.module.css';

const Intro = () => {
    const navigate = useNavigate();

    const handleStartTest = () => {
        navigate('/mbti');
    }

    return (
        <div className={styles["intro"]}>
            <h2>Personality test</h2>
            <p>Answer a couple of questions (5min max) so that our algorithm can find the best job for you.</p>
            <div className={styles['instructions']}>
                <ul>
                    <li>You will be shown two statements at a time.</li>
                    <li>On a scale from 1 to 7, indicate which statement you agree with the most.</li>
                    <li>1 means you agree with the first statement, 7 means you agree with the second statement.</li>
                </ul>
            </div>
            <button onClick={handleStartTest}>Start the Test</button>
        </div>
    );
};

export default Intro;
