// src/HomePage.js
import React from 'react';
import styles from '../styles/HomePage.module.css';
import EnterpriseHome from '../components/EnterpriseHome';
import Footer from '../components/Footer';

function HomePageEnterprise() {
  return (
    <div className={styles['home-page']}>
      <EnterpriseHome />
      <Footer />
    </div >
  );
}

export default HomePageEnterprise;
