import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/AdminCompanyPage.module.css';

// Modal for adding a new job offer
import JobModal from '../components/JobModal';

const AdminCompanyPage = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const token = getSessionToken();

    // Fetch jobs for the selected company

    const fetchJobs = useCallback(async (companyId) => {
        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/getjobcompany?company_id=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setJobs(data);
            } else {
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    }, [token]);

    // Fetch companies associated with the user
    const fetchCompanies = useCallback(async () => {
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/getusercompany', {
                headers: {
                    'Content-Type': 'application/json',
                    'sessionKey': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCompanies(data);
                if (data.length === 1) {
                    setSelectedCompany(data[0]); // Auto-select if only one company
                    fetchJobs(data[0].company_id);
                }
            } else {
                console.error('Failed to fetch companies');
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }, [token, fetchJobs]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    // Handle company selection
    const handleCompanySelect = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.company_id === companyId);
        setSelectedCompany(company);
        fetchJobs(company.company_id);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter jobs based on the search term
    const filteredJobs = jobs.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()));

    // Open the modal to add a new job
    const openModal = () => setIsModalOpen(true);

    // Close the modal
    const closeModal = () => setIsModalOpen(false);

    const openEditModal = (job) => {
        // Open the modal with the job data and set the edit flag
        setIsEditModalOpen(true);
        setSelectedJob(job);
    }

    const closeEditModal = () => setIsEditModalOpen(false);

    const deleteJob = async (jobId) => {
        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/removejobcompany`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionKey': token
                },
                body: JSON.stringify({ company_id: selectedCompany.company_id, job_id: jobId })
            });
            if (response.ok) {
                fetchJobs(selectedCompany.company_id);
            } else {
                console.error('Failed to delete job');
            }
        } catch (error) {
            console.error('Error deleting job:', error);
        }
    }


    return (
        <div className={styles["admin-company-page"]}>
            <h1>Company Administration</h1>

            {/* Company Dropdown */}
            {companies.length > 1 && (
                <select value={selectedCompany?.company_id || ''} onChange={handleCompanySelect}>
                    <option value="" disabled>Select a company</option>
                    {companies.map(company => (
                        <option key={company.company_id} value={company.company_id}>
                            {company.company_name}
                        </option>
                    ))}
                </select>
            )}

            {selectedCompany && (
                <>
                    {/* Search Bar and Add Job Button */}
                    <div className={styles["job-management-header"]}>
                        <input
                            type="text"
                            placeholder="Search job offers..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button onClick={openModal} className="add-job-button">Add New Job</button>
                    </div>

                    {isModalOpen && (
                        <JobModal
                            selectedCompany={selectedCompany}
                            closeModal={closeModal}
                            fetchJobs={fetchJobs} 
                        />
                    )}

                    {isEditModalOpen && (
                        <JobModal
                            selectedCompany={selectedCompany}
                            closeModal={closeEditModal}
                            fetchJobs={fetchJobs}
                            edit={true}
                            data={selectedJob}
                        />
                    )}

                    {/* Job List */}

                    <div className={styles["job-list"]}>
                        {filteredJobs.map(job => (
                            <div key={job.job_id} className={styles["job-item"]}>
                                <span>{job.title}</span>
                                <a href={`/admin/job?jobId=${job.job_id}`}><button className={styles["view-button"]}>View</button></a>
                                <button className={styles["edit-button"]} onClick={() => openEditModal(job)}>Edit</button>
                                <button className={styles["delete-button"]} onClick={() => deleteJob(job.job_id)}>Delete</button>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default AdminCompanyPage;
