// src/components/JobCard.js
import React, { useState } from 'react';
import { getSessionToken } from '../utils/Auth';
import Button from './Button';
import IconButton from './IconButton';
import Modal from './Modal';
import styles from "../styles/JobCard.module.css";

function JobCard({ id, title, company, location, type, description, link }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const token = getSessionToken();
    console.log("id", id);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const likeOffer = async (id) => {
        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/addfavoritejob`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    sessionKey: token
                },
                body: JSON.stringify({ jobID: id })
            });

            console.log("trying to like offer", id);

            if (response.ok) {
                console.log(`Offer ${id} liked successfully`);
                // Perform any additional actions after the offer is liked
            } else {
                console.error(`Failed to like offer ${id}`);
                // Handle the error case
            }
        } catch (error) {
            console.error(`An error occurred while liking offer ${id}:`, error);
            // Handle the error case
        }
    };

    const reportOffer = async (id) => {

    };

    return (
        <div className={styles["job-card"]}>
            <div className={styles["job-card-header"]}>
                <div className={styles["job-card-title"]}>
                    <h2>{title}</h2>
                </div>
                <div className={styles["job-card-actions"]}>
                    <IconButton icon="heart" onClick={() => likeOffer(id)} />
                    <IconButton icon="flag" onClick={() => reportOffer(id)} />
                </div>
            </div>
            <div className={styles["job-card-body"]}>
                <h3>{company} - {location}</h3>
                <p>{type}</p>
            </div>
            <div className={styles["job-card-footer"]}>
                <Button text="View Job" onClick={openModal} className='apply-button' />
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <div className={styles["modal-header"]}>
                    <h2>{title}</h2>
                </div>
                <h3 className={styles["company-location"]}>{company} - {location}</h3>
                <p className={styles["job-type"]}>{type}</p>
                <div className={styles["job-description"]}>
                    <p>{description}</p>
                </div>
                <div className={styles["modal-footer"]}>
                    <Button text="Apply Now" link={link} className={styles["apply-button"]} />
                </div>
            </Modal>
        </div>
    );
}

export default JobCard;
