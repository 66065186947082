import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import Button from './Button';
import styles from '../styles/Candidats.module.css';

// Wanteed logo to be displayed for candidates that come from Wanteed
//import wanteedLogo from '../assets/logo.png';


const Candidats = (jobData) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortCriteria, setSortCriteria] = useState('pertinence');
    const [sortOrder, setSortOrder] = useState('ascending');
    const sessionToken = getSessionToken();
    const [isLoading, setIsLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);

    const job_id = jobData.jobData.job_id;
    const company_id = jobData.jobData.company_id;

    useEffect(() => {
        const fetchAndSetRecommendedCandidates = async () => {
            const response = await fetch(`https://api.wanteed-job.com/getrecommendatedcandidate?job_id=${job_id}&company_id=${company_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': sessionToken
                }
            });
            const candidates = await response.json();
            setCandidates(candidates);
            setFilteredCandidates(candidates);
            setIsLoading(false);
        }
        fetchAndSetRecommendedCandidates();
    }, [job_id, company_id, sessionToken]);

    // Function to handle filtering and sorting
    const filterAndSortCandidates = useCallback(() => {
        // Filter based on search term
        let localCandidates = candidates;
        if (searchTerm) {
            localCandidates = candidates.filter(candidate =>
                candidate.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                candidate.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        else if (searchTerm === '') {
            localCandidates = candidates;
        }

        // Sort candidates based on selected criteria and order
        localCandidates.sort((a, b) => {
            const diff = a[sortCriteria] - b[sortCriteria];
            return sortOrder === 'ascending' ? -diff : diff;
        });

        setFilteredCandidates(localCandidates);
    }, [searchTerm, sortCriteria, sortOrder, candidates]);

    // Handle changes in search input
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle changes in sort criteria
    const handleSortCriteriaChange = (e) => {
        setSortCriteria(e.target.value);
    };

    // Handle changes in sort order
    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    // Trigger filtering and sorting when search term, sort criteria, or sort order changes
    useEffect(() => {
        filterAndSortCandidates();
    }, [searchTerm, sortCriteria, sortOrder, filterAndSortCandidates]);

    const OriginColor = {
        'WANTEED': '#007bff',
        'DEMARCHE': '#28a745',
        'SPONTANE': '#ffc107'
    };

    return (
        isLoading ? <div>Loading...</div> :
            <div>
                <div className={styles["filter-bar"]}>
                    <input
                        type="text"
                        placeholder="Rechercher un candidat..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className={styles["filter-bar"]}>
                    <select className={styles["filter-select"]} value={sortCriteria} onChange={handleSortCriteriaChange}>
                        <option value="pertinence">Pertinence</option>
                        <option value="softSkills">Soft skills</option>
                        <option value="hardSkills">Compétences techniques</option>
                        <option value="cultureFit">Culture fit</option>
                        <option value="teamFit">Team fit</option>
                    </select>
                    <select className={styles["filter-select"]} value={sortOrder} onChange={handleSortOrderChange}>
                        <option value="ascending">Ascendant</option>
                        <option value="descending">Descendant</option>
                    </select>
                </div>
                <div className={styles["candidate-grid"]}>
                    {filteredCandidates.map(candidate => (
                        <div key={candidate.user_id} className={styles["candidate-card"]}>
                            <div
                                className={styles["origin-flag"]}
                                style={{ backgroundColor: OriginColor[candidate.origin] }}
                            >
                                {`Origine : ${candidate.origin} \nAjouté le : ${new Date(candidate.added_time).toLocaleDateString()}`}
                            </div>
                            <div className={styles["global-score-circle"]}>
                                <div className={styles["circle-background"]}>
                                    <div
                                        className={styles["circle-progress"]}
                                        style={{
                                            '--progress': `${candidate.global}%`
                                        }}
                                    />
                                    <div className={styles["circle-content"]}>
                                        {candidate.global.toPrecision(3)}%
                                    </div>
                                </div>
                            </div>
                            <h3 className={styles["candidate-name"]}>{candidate.first_name + " " + candidate.last_name}</h3>
                            <div className={styles["relevance-score"]}>
                                <div className={styles["candidate-grades"]}>
                                    <p><span>Hard skills :</span> <span>{candidate.hardskill.toPrecision(3)}%</span></p>
                                    <p><span>Culture fit :</span> <span>{candidate.culture.toPrecision(3)}%</span></p>
                                    <p><span>Soft skills :</span> <span>{candidate.softskill.toPrecision(3)}%</span></p>
                                    <p><span>Team fit :</span> <span>{candidate.mbti.toPrecision(3)}%</span></p>
                                </div>
                            </div>
                            <Button className={styles["view-candidate-btn"]} variant='secondary' text={"Voir le candidat"} link={`/candidatepersona/${candidate.user_id}`} />
                            <Button className={styles["view-candidate-btn"]} text={"Profil LinkedIn"} link={candidate.linkedin} />
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default Candidats;
