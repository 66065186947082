import React, { useEffect, useState } from 'react';
import styles from '../styles/DigitalNetwork.module.css';

const DigitalNetwork = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 500); // Simulates the load time for the animation
    }, []);

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 800 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${styles['digital-network']} ${isLoaded ? styles['loaded'] : ''}`}
        >
            {/* Nodes */}
            <circle cx="100" cy="100" r="5" className={styles["node"]} />
            <circle cx="200" cy="150" r="7" className={styles["node"]} />
            <circle cx="300" cy="80" r="4" className={styles["node"]} />
            <circle cx="400" cy="200" r="6" className={styles["node"]} />
            <circle cx="500" cy="120" r="5" className={styles["node"]} />
            <circle cx="250" cy="250" r="8" className={styles["node"]} />
            <circle cx="150" cy="300" r="4" className={styles["node"]} />
            <circle cx="350" cy="300" r="6" className={styles["node"]} />
            <circle cx="450" cy="350" r="5" className={styles["node"]} />
            <circle cx="600" cy="100" r="7" className={styles["node"]} />
            <circle cx="650" cy="200" r="6" className={styles["node"]} />

            {/* Connections */}
            <line x1="100" y1="100" x2="200" y2="150" className={styles["connection"]} />
            <line x1="200" y1="150" x2="300" y2="80" className={styles["connection"]} />
            <line x1="300" y1="80" x2="400" y2="200" className={styles["connection"]} />
            <line x1="400" y1="200" x2="500" y2="120" className={styles["connection"]} />
            <line x1="500" y1="120" x2="600" y2="100" className={styles["connection"]} />
            <line x1="600" y1="100" x2="650" y2="200" className={styles["connection"]} />
            <line x1="650" y1="200" x2="450" y2="350" className={styles["connection"]} />
            <line x1="450" y1="350" x2="350" y2="300" className={styles["connection"]} />
            <line x1="350" y1="300" x2="250" y2="250" className={styles["connection"]} />
            <line x1="250" y1="250" x2="150" y2="300" className={styles["connection"]} />
            <line x1="150" y1="300" x2="100" y2="100" className={styles["connection"]} />
            <line x1="100" y1="100" x2="300" y2="80" className={styles["connection"]} />
            <line x1="200" y1="150" x2="400" y2="200" className={styles["connection"]} />
            <line x1="500" y1="120" x2="250" y2="250" className={styles["connection"]} />
            <line x1="450" y1="350" x2="150" y2="300" className={styles["connection"]} />
            <line x1="350" y1="300" x2="200" y2="150" className={styles["connection"]} />
            <line x1="600" y1="100" x2="400" y2="200" className={styles["connection"]} />
            <line x1="650" y1="200" x2="500" y2="120" className={styles["connection"]} />
            <line x1="250" y1="250" x2="100" y2="100" className={styles["connection"]} />
            <line x1="450" y1="350" x2="300" y2="80" className={styles["connection"]} />
            <line x1="350" y1="300" x2="100" y2="100" className={styles["connection"]} />
            <line x1="150" y1="300" x2="400" y2="200" className={styles["connection"]} />
            <line x1="200" y1="150" x2="500" y2="120" className={styles["connection"]} />
            <line x1="350" y1="300" x2="500" y2="120" className={styles["connection"]} />
        </svg>
    );
};

export default DigitalNetwork;
