// src/HomePage.js
import React from 'react';
import styles from '../styles/HomePage.module.css';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function HomePage() {
  return (
    <div className={styles["home-page"]}>
      <Hero />
      <Footer />
    </div >
  );
}

export default HomePage;
