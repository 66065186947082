// src/pages/ViewJob/Stats.js
import React from 'react';
import { Radar, Bar, Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/Stats.module.css';

const Stats = ({ jobData }) => {
    const [origins, setOrigins] = React.useState([]);
    const sessionToken = getSessionToken();

    const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

    // Prepare the data for the chart
    const processDataForChart = (data) => {
        const dates = [...new Set(data.map(d => formatDate(d.add_date)))]; // Extract unique dates
        const uniqueOrigins = [...new Set(data.map(d => d.origin))]; // Extract unique origins

        if (dates.length === 0 || uniqueOrigins.length === 0) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const datasets = uniqueOrigins.map(origin => {
            const originData = dates.map(date => {
                const filteredData = data.filter(d => formatDate(d.add_date) === date && d.origin === origin);
                return filteredData.reduce((sum, d) => sum + d.candidate_count, 0);
            });

            return {
                label: origin,
                data: originData,
                fill: false,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color for each line
            };
        });

        return { labels: dates, datasets };
    };

    React.useEffect(() => {
        fetch(`https://api.wanteed-job.com/getjobcandidatestats?company_id=${jobData.company_id}&job_id=${jobData.job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'sessionkey': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data)
                setOrigins(data);
            })
    }, [jobData.company_id, jobData.job_id, sessionToken]);

    const chartData = processDataForChart(origins);


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };


    // DUMMY DATA
    const candidateOrigins = {
        Spontané: 15,
        Chassé: 20,
        Wanteed: 10,
    };

    const totalCandidates = Object.values(candidateOrigins).reduce((a, b) => a + b, 0);

    const dummyData = [{ "trait_name": "Challenging work", "value": 5, "type": "Culture" },
    { "trait_name": "Innovative product", "value": 4, "type": "Culture" },
    { "trait_name": "Data driven decisions", "value": 4, "type": "Culture" },
    { "trait_name": "Great tech and tools", "value": 3, "type": "Culture" },
    { "trait_name": "Organisation", "value": 0, "type": "Behavior" },
    { "trait_name": "adaptability", "value": 0, "type": "Behavior" },
    { "trait_name": "independently", "value": 0, "type": "Behavior" },
    { "trait_name": "sense of responsibility", "value": 0, "type": "Behavior" },
    { "trait_name": "self - discipline", "value": 0, "type": "Behavior" },
    { "trait_name": "stress management", "value": 0, "type": "Behavior" },
    { "trait_name": "boldness", "value": 0, "type": "Behavior" },
    { "trait_name": "Diversity and inclusion", "value": 2, "type": "Culture" },
    { "trait_name": "initiative sense", "value": 0, "type": "Behavior" },
    { "trait_name": "time management", "value": 0, "type": "Behavior" },
    { "trait_name": "communication", "value": 0, "type": "Relational" },
    { "trait_name": "cooperation", "value": 0, "type": "Relational" },
    { "trait_name": "empathy", "value": 0, "type": "Relational" },
    { "trait_name": "negotiation", "value": 0, "type": "Relational" },
    { "trait_name": "ability to delegate", "value": 0, "type": "Relational" },
    { "trait_name": "tolerance", "value": 0, "type": "Relational" },
    { "trait_name": "self confidence", "value": 0, "type": "Relational" },
    { "trait_name": "conflict resolution", "value": 0, "type": "Relational" },
    { "trait_name": "Meaningful work", "value": 4, "type": "Culture" },
    { "trait_name": "leadership", "value": 0, "type": "Relational" },
    { "trait_name": "networking", "value": 0, "type": "Relational" },
    { "trait_name": "memory", "value": 0, "type": "Cognitive" },
    { "trait_name": "concentration", "value": 0, "type": "Cognitive" },
    { "trait_name": "critical thinker", "value": 0, "type": "Cognitive" },
    { "trait_name": "creativity", "value": 0, "type": "Cognitive" },
    { "trait_name": "resilience", "value": 0, "type": "Cognitive" },
    { "trait_name": "perseverance", "value": 0, "type": "Cognitive" },
    { "trait_name": "ability to synthesize", "value": -1, "type": "Cognitive" },
    { "trait_name": "curiosity", "value": 0, "type": "Cognitive" },
    { "trait_name": "Development and progression", "value": 6, "type": "Culture" },
    { "trait_name": "Flexibility and wellbeing", "value": 5, "type": "Culture" },
    { "trait_name": "Recognition and reward", "value": 3, "type": "Culture" },
    { "trait_name": "Working with great people", "value": 6, "type": "Culture" },
    { "trait_name": "Transparency and respect", "value": 4, "type": "Culture" },
    { "trait_name": "Progressive leadership", "value": 6, "type": "Culture" },
    { "trait_name": "Behavior", "value": 2, "type": "Softskill" },
    { "trait_name": "Cognitive", "value": 1, "type": "Softskill" },
    { "trait_name": "Extroverts", "value": 3, "type": "MBTI" },
    { "trait_name": "Feelers", "value": 0, "type": "MBTI" },
    { "trait_name": "Introverts", "value": 2, "type": "MBTI" },
    { "trait_name": "Judgers", "value": 5, "type": "MBTI" },
    { "trait_name": "Intuitive", "value": 0, "type": "MBTI" },
    { "trait_name": "Perceivers", "value": 1, "type": "MBTI" },
    { "trait_name": "Relational", "value": 0, "type": "Softskill" },
    { "trait_name": "Sensors", "value": 8, "type": "MBTI" },
    { "trait_name": "Thinkers", "value": 6, "type": "MBTI" }]

    const personaData = dummyData.filter(trait => trait.type === "Softskill");
    const cultureData = { "Challenging work": 5, "Innovative product": 4, "Data driven decisions": 4, "Great tech and tools": 3, "Diversity and inclusion": 2, "Meaningful work": 4, "Development and progression": 6, "Flexibility and wellbeing": 5, "Recognition and reward": 3, "Working with great people": 6, "Transparency and respect": 4, "Progressive leadership": 6 };
    const cognitiveData = dummyData.filter(trait => trait.type === "Cognitive");
    const relationalData = dummyData.filter(trait => trait.type === "Relational");
    const behaviorData = dummyData.filter(trait => trait.type === "Behavior");
    const mbtiData = dummyData.filter(trait => trait.type === "MBTI");

    const radarDataPersona = {
        labels: personaData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Soft skills',
                data: personaData.map(trait => trait.value),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataBehaviour = {
        labels: behaviorData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Behaviour Traits',
                data: behaviorData.map(trait => trait.value),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataRelational = {
        labels: relationalData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Relational Traits',
                data: relationalData.map(trait => trait.value),
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCognitive = {
        labels: cognitiveData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Cognitive Traits',
                data: cognitiveData.map(trait => trait.value),
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCulture = {
        labels: Object.keys(cultureData),
        datasets: [
            {
                label: 'Culture Traits',
                data: Object.values(cultureData),
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const mbtiTraitPairs = [
        ['Extroverts', 'Introverts'],
        ['Feelers', 'Thinkers'],
        ['Judgers', 'Perceivers'],
        ['Intuitive', 'Sensors']
    ];
    const mbtiValuesA = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[0]);
        return trait ? trait.value : 0;
    });
    const mbtiValuesB = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[1]);
        return trait ? trait.value : 0;
    });

    const bidirectionalData = {
        labels: mbtiTraitPairs.map(pair => `${pair[0]} / ${pair[1]}`),
        datasets: [
            {
                label: 'Left Traits',
                data: mbtiValuesA.map(value => -value),
                backgroundColor: 'rgba(75,192,192,0.5)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
            {
                label: 'Right Traits',
                data: mbtiValuesB,
                backgroundColor: 'rgba(255,99,132,0.5)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
            }
        ],
    };

    const bidirectionalOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return Math.abs(value);
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += Math.abs(context.raw);
                        }
                        return label;
                    },
                },
            },
        },
    };
    // END DUMMY DATA

    const originsOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Candidates Origin Over Time',
            },
        },
    };

    const links = [
        { name: 'Lien pour vos annonces (Spontané)', url: 'https://link-for-spontanius-cand/id_job' },
        { name: 'Lien pour vos démarchages (Chassé)', url: 'https://link-for-hunt-cand/id_job' }
    ];

    return (
        <div className={styles["stats-container"]}>
            <h1>{jobData.title}</h1>
            <h2>{jobData.company}</h2>
            <div className={styles["job-stats"]}>
                <p><strong>Localisation:</strong> {jobData.city}, {jobData.country}</p>
                <p><strong>Type:</strong> {jobData.type}</p>
                <p><strong>Salaire:</strong> {jobData.salary} €</p>
                <p><strong>Date de publication:</strong> {new Date(jobData.release_date).toLocaleDateString()}</p>
                <p><strong>Description:</strong> {jobData.description}</p>
            </div>

            <div className={styles["candidate-stats"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3>Origine des candidats</h3>
                <div className={styles["chart-container"]} style={{ width: '600px', justifyContent: 'center' }}>
                    {chartData.datasets.length > 0 ? ( // Check if there is data to render
                        <Line data={chartData} options={originsOptions} />
                    ) : (
                        <p>Pas de données disponibles</p>
                    )}

                </div>
                <p><strong>Nombre total de candidats :</strong> {totalCandidates}</p>
            </div>
            <div className={styles["candidate-stats"]}>
                <h3>Partager les liens</h3>
                <ul className={styles["share-links"]}>
                    {links.map((link, index) => (
                        <li key={index} className={styles["share-link-item"]}>
                            <strong>{link.name}:</strong>
                            <div className={styles["share-link-container"]}>
                                <input
                                    type="text"
                                    value={link.url}
                                    readOnly
                                    className={styles["share-link-input"]}
                                    onClick={(e) => copyToClipboard(link.url)}
                                />
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className={styles["copy-icon"]}
                                    onClick={() => copyToClipboard(link.url)}
                                    size='lg'
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className={styles["persona-analysis"]}>
                <h2>Persona du candidat idéal</h2>
                <div className={styles["charts-container"]}>
                    <div className={styles["radar-chart"]}>
                        <h4>Soft skills</h4>
                        <Radar data={radarDataPersona} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <h4>Behaviour Traits</h4>
                        <Radar data={radarDataBehaviour} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <h4>Relational Traits</h4>
                        <Radar data={radarDataRelational} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <h4>Cognitive Traits</h4>
                        <Radar data={radarDataCognitive} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <h4>Culture Traits</h4>
                        <Radar data={radarDataCulture} />
                    </div>
                    <div className={styles["bar-chart"]}>
                        <h4>Personnalité</h4>
                        <Bar data={bidirectionalData} options={bidirectionalOptions} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Stats;
