import React, { useState } from 'react';
import styles from '../styles/Form.module.css';

const ExperienceForm = ({ onAddExperience }) => {
    const [formData, setFormData] = useState({
        domain: '',
        profession: '',
        duration: '',
        description: '',
        company: '',
        appreciation: '',
        performance: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/addexperience', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                const newExperience = await response.json();
                onAddExperience(newExperience);
                setFormData({
                    domain: '',
                    profession: '',
                    duration: '',
                    description: '',
                    company: '',
                    appreciation: '',
                    performance: ''
                });
            } else {
                console.error('Failed to add experience');
            }
        } catch (error) {
            console.error('Error adding experience:', error);
        }
    };

    return (
        <form className={styles["form"]} onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
                <label>Domain:</label>
                <input type="text" name="domain" value={formData.domain} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Profession:</label>
                <input type="text" name="profession" value={formData.profession} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Duration:</label>
                <input type="text" name="duration" value={formData.duration} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Description:</label>
                <textarea name="description" value={formData.description} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label>Company:</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Appreciation:</label>
                <input type="text" name="appreciation" value={formData.appreciation} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Performance:</label>
                <input type="text" name="performance" value={formData.performance} onChange={handleChange} required />
            </div>
            <button type="submit" className={styles["button"]}>Add Experience</button>
        </form>
    );
};

export default ExperienceForm;
