import React, { useState, useEffect } from 'react';
import { Radar, Bar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/PersonaPage.module.css';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const CandidatePersonaPage = () => {
    const [personaData, setPersonaData] = useState([]);
    const [behaviourData, setBehaviourData] = useState([]);
    const [relationalData, setRelationalData] = useState([]);
    const [cognitiveData, setCognitiveData] = useState([]);
    const [cultureData, setCultureData] = useState([]);
    const [mbtiData, setMbtiData] = useState([]);
    const sessionKey = getSessionToken();
    const [candidateName, setCandidateName] = useState('');
    const [email, setEmail] = useState('');
    const [linkedin, setLinkedin] = useState('');

    // Get the candidateID from the URL (e.g. /candidatepersona/123)
    const candidateID = window.location.pathname.split('/').pop();
    console.log("candidate page", candidateID);

    useEffect(() => {
        console.log("useEffect called");
        if (!sessionKey || !candidateID) {
            console.error("Missing sessionKey or candidateID");
            return;
        }


        fetch(`https://dev.api.wanteed-job.com/getuser?userid=${candidateID}`, {
            headers: {
                'Content-Type': 'application/json',
                'sessionkey': sessionKey
            }
        }).then(response => response.json())
            .then(data => {
                setCandidateName(data.first_name + ' ' + data.last_name);
                setEmail(data.email);
                setLinkedin(data.linkedin);
                console.log("candidate data", data);
            })
            .catch(error => console.error('Error fetching candidate data:', error));

        fetch(`https://dev.api.wanteed-job.com/getcandidatepersonna?candidateID=${candidateID}`, {
            headers: {
                'Content-Type': 'application/json',
                'sessionkey': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("personna data", data);
                // Filter for soft skills only
                const softSkills = data.filter(trait => (trait.trait_id === "R" || trait.trait_id === "B" || trait.trait_id === "C") && trait.value !== 0);
                setPersonaData(softSkills);

                // Filter for behaviour traits only
                const behaviourTraits = data.filter(trait => parseInt(trait.trait_id) >= 13 && parseInt(trait.trait_id) <= 21 && trait.value !== 0);
                setBehaviourData(behaviourTraits);

                // Filter for relational traits only
                const relationalTraits = data.filter(trait => parseInt(trait.trait_id) >= 22 && parseInt(trait.trait_id) <= 31 && trait.value !== 0);
                setRelationalData(relationalTraits);

                // Filter for cognitive traits only
                const cognitiveTraits = data.filter(trait => parseInt(trait.trait_id) >= 32 && parseInt(trait.trait_id) <= 39 && trait.value !== 0);
                setCognitiveData(cognitiveTraits);

                // Filter for MBTI traits only
                const mbtiTraits = data.filter(trait => trait.type === "MBTI");
                setMbtiData(mbtiTraits);

                // Filter for culture traits only
                const cultureTraits = data.filter(trait => parseInt(trait.trait_id) >= 1 && parseInt(trait.trait_id) <= 12 && trait.value !== 0);
                console.log(cultureTraits);
                setCultureData(cultureTraits);
            })
            .catch(error => console.error('Error fetching persona data:', error));
    }, [sessionKey, candidateID]);

    const radarOptions = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                }
            }
        }
    };

    const radarDataPersona = {
        labels: personaData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Persona Traits',
                data: personaData.map(trait => trait.value),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataBehaviour = {
        labels: behaviourData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Behaviour Traits',
                data: behaviourData.map(trait => trait.value),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataRelational = {
        labels: relationalData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Relational Traits',
                data: relationalData.map(trait => trait.value),
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCognitive = {
        labels: cognitiveData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Cognitive Traits',
                data: cognitiveData.map(trait => trait.value),
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCulture = {
        labels: cultureData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Culture Traits',
                data: cultureData.map(trait => trait.value),
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    // Prepare MBTI data for bidirectional bar graph
    const mbtiTraitPairs = [
        ['E', 'I'],
        ['S', 'N'],
        ['T', 'F'],
        ['J', 'P'],
    ];

    const mbtiValuesA = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_id === pair[0]);
        return trait ? trait.value : 0;
    });
    const mbtiValuesB = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_id === pair[1]);
        return trait ? trait.value : 0;
    });

    const mbtiLabel = mbtiTraitPairs.map(pair => {
        const traitA = mbtiData.find(trait => trait.trait_id === pair[0]);
        const traitB = mbtiData.find(trait => trait.trait_id === pair[1]);
        return traitA && traitB ? traitA.trait_name + ' / ' + traitB.trait_name :'';
    });

    const bidirectionalData = {
        labels: mbtiLabel,
        datasets: [
            {
                label: 'Left Traits',
                data: mbtiValuesA.map(value => -value),
                backgroundColor: 'rgba(75,192,192,0.5)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
            {
                label: 'Right Traits',
                data: mbtiValuesB,
                backgroundColor: 'rgba(255,99,132,0.5)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
            }
        ],
    };

    const bidirectionalOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                min: -10,
                max: 10,
                ticks: {
                    callback: function (value) {
                        return Math.abs(value);
                    },
                },
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += Math.abs(context.raw);
                        }
                        return label;
                    },
                },
            },
        }
    };

    return (
        <div className={styles["persona-page"]}>
            <div className={styles['back-button']}>
                <button onClick={() => window.history.back()}>⇐ Retour à l'offre</button>
            </div>
            <div className={styles["candidate-info"]}>
                <p className={styles['candidatepersonna-candidate-name']}>{candidateName}</p>
                <p className={styles['candidatepersonna-candidate-other']}>{email}</p>
                <button onClick={() => window.open("https://www.linkedin.com/in/erwann-mevel/", '_blank')}>{linkedin} LinkedIn</button>
            </div>
            <h2>Candidate personna</h2>
            <div className={styles["chart-container"]}>
                <div className={styles["radar-chart"]}>
                    <h2>Soft skills</h2>
                    <Radar data={radarDataPersona} options={radarOptions} />
                </div>
                <div className={styles["radar-chart"]}>
                    <h2>Behaviour Traits</h2>
                    <Radar data={radarDataBehaviour} options={radarOptions} />
                </div>
                <div className={styles["radar-chart"]}>
                    <h2>Relational Traits</h2>
                    <Radar data={radarDataRelational} options={radarOptions} />
                </div>
                <div className={styles["radar-chart"]}>
                    <h2>Cognitive Traits</h2>
                    <Radar data={radarDataCognitive} options={radarOptions} />
                </div>
                <div className={styles["radar-chart"]}>
                    <h2>Culture Traits</h2>
                    <Radar data={radarDataCulture} options={radarOptions} />
                </div>
                <div className={styles["bar-chart"]}>
                    <h2>Personality</h2>
                    <Bar data={bidirectionalData} options={bidirectionalOptions} />
                </div>
            </div>
        </div>
    );
};

export default CandidatePersonaPage;