import React from 'react';
import styles from '../styles/ToggleButton.module.css';

const ToggleButton = ({ view, setView }) => {
    return (
        <div className={styles["toggle-button"]}>
            <button
                className={view === 'stats' ? styles['active'] : ''}
                onClick={() => setView('stats')}
            >
                Stats
            </button>
            <button
                className={view === 'candidates' ? styles['active'] : ''}
                onClick={() => setView('candidates')}
            >
                Candidats
            </button>
        </div>
    );
};

export default ToggleButton;
