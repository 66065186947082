import React, { useState, useEffect } from 'react';
import styles from '../styles/Form.module.css';

const HardSkillForm = ({ onAddHardSkill }) => {
    const [formData, setFormData] = useState({
        hardskill: '',
        type: '',
        category: ''
    });
    const [domains, setDomains] = useState({});
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await fetch('https://dev.api.wanteed-job.com/getDomains', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Fetched domains:', data);
                    setDomains(data);
                    setCategories(Object.keys(data));
                } else {
                    console.error('Failed to fetch domains');
                }
            } catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, []);

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            category: selectedCategory,
            type: ''
        }));
        setTypes(domains[selectedCategory] || []);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        let token = localStorage.getItem('token');
        e.preventDefault();
        console.log('Form data:', formData);
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/addhardskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': `${token}`
                },
                body: JSON.stringify({
                    hardskill: formData.hardskill,
                    type: formData.type
                })
            });
            console.log('Response:', response);
            if (response.ok) {
                setFormData({
                    hardskill: '',
                    type: '',
                    category: ''
                });
            } else {
                console.error('Failed to add hard skill');
            }
        } catch (error) {
            console.error('Error adding hard skill:', error);
        }
    };

    return (
        <form className={styles["form"]} onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
                <label>Intitulé :</label>
                <input type="text" name="hardskill" value={formData.hardskill} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Catégorie :</label>
                <select name="category" value={formData.category} onChange={handleCategoryChange} required>
                    <option value="" disabled>Select Category</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>
            </div>
            <div className={styles["form-group"]}>
                <label>Type :</label>
                <select name="type" value={formData.type} onChange={handleChange} required disabled={!formData.category}>
                    <option value="" disabled>Select Type</option>
                    {types.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                    ))}
                </select>
            </div>
            <button type="submit" className={styles["button"]}>Add Hard Skill</button>
        </form>
    );
};

export default HardSkillForm;
