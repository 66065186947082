import React from 'react';
import styles from '../styles/Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles['footer']}>
            <p>© 2024 Wanteed</p>

            <div className={styles['footer-links']}>
                <a href='/privacy'>Privacy Policy</a>
                <a href='/tos'>Terms of Service</a>
                <a href='/'>Contact</a>
            </div>
        </footer>
    );
};

export default Footer;