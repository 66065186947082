import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/CultureFitPage.module.css';

const CultureFitPage = () => {
    const [traits, setTraits] = useState([]);
    const navigate = useNavigate();
    const sessionKey = getSessionToken();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/getculture', {
            headers: {
                'Content-Type': 'application/json',
                'sessionkey': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => setTraits(data))
            .catch(error => console.error('Error fetching culture traits:', error));
    }, [sessionKey]);

    const handleSliderChange = (trait, value) => {
        setTraits({
            ...traits,
            [trait]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const promises = Object.keys(traits).map(trait =>
            fetch('https://dev.api.wanteed-job.com/answerculture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': sessionKey
                },
                body: JSON.stringify({ culture: trait, value: traits[trait] })
            })
        );

        Promise.all(promises)
            .then(() => {
                alert('Your responses have been submitted.');
                navigate('/'); // Replace with actual navigation route
            })
            .catch(error => console.error('Error submitting responses:', error));
    };

    return (
        <div className={styles["culture-fit-page"]}>
            <div className={styles["test-switcher"]}>
                <button className={styles["test-button-active"]} disabled>Culture fit</button>
                <button className={styles["test-button"]} onClick={() => navigate('/softskilltest')}>Soft skills</button>
                <button className={styles["test-button"]} onClick={() => navigate('/hardskills')}>Hard skills</button>
                <button className={styles["test-button"]} onClick={() => navigate('/mbti')}>Team fit</button>
            </div>
            <h1>Culture Fit Assessment</h1>
            <form onSubmit={handleSubmit}>
                {traits.map(trait => (
                    <div key={trait.trait_id} className={styles["trait-container"]}>
                        <label className={styles["trait-label"]}>{trait.trait_name}</label>
                        <input
                            type="range"
                            min="0"
                            max="7"
                            value={trait.value}
                            onChange={(e) => handleSliderChange(trait, e.target.value)}
                            className="trait-slider"
                        />
                        <div className={styles["trait-value"]}>{traits[trait]}</div>
                    </div>
                ))}
                <button type="submit" className={styles["submit-button"]}>Submit</button>
            </form>
        </div>
    );
};

export default CultureFitPage;
