import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Stats from '../components/Stats';
import Candidats from '../components/Candidats';
import ToggleButton from '../components/ToggleButton';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/ViewJob.module.css';

const ViewJob = () => {
    const location = useLocation();
    const [view, setView] = useState('stats');
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track errors

    const token = getSessionToken();

    // Function to parse query string
    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    const jobId = getQueryParam('jobId');

    useEffect(() => {
        if (!jobId || !token) {
            console.error("jobId or token is missing");
            return;
        }

        const fetchJobData = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch('https://dev.api.wanteed-job.com/getjob?jobID=' + jobId, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'sessionkey': token,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log("DATA RECEIVED (fetchJobData):", data); // Debugging: Check data
                setJobData(data);
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJobData();
    }, [jobId, token]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles["view-job-container"]}>
            <ToggleButton view={view} setView={setView} />
            {view === 'stats' ? <Stats jobData={jobData[0]} /> : <Candidats jobData={jobData[0]} />}
        </div>
    );
};

export default ViewJob;
